import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { i18n_ru } from './ru'
import { i18n_kk } from './kk'

function getDefaults() {
  return {
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupSessionStorage: 'i18nextLng',
    caches: ['localStorage'],
    excludeCacheFor: ['cimode'],
  };
}

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ru: i18n_ru,
      kk: i18n_kk,
    },
    fallbackLng: "ru",
    detection: getDefaults(),
    supportedLngs: ['ru', 'kk'],
  });

export default i18n;
