import Birzhan from '../../assets/images/about/Birzhan.png';
import Nikita from '../../assets/images/about/Nikita.jpg';
import { useTranslation } from "react-i18next";

export default function Team() {
  const { t } = useTranslation();
  const team = [
    { name: t('about.birzhan'), role: t('about.birzhan_role'), img: Birzhan },
    { name: t('about.nikita'), role: t('about.nikita_role'), img: Nikita },
  ];

  return (
    <section className="team d-flex flex-column">
      <div className="team-top d-flex flex-column">
        <div className="about-title">{t('about.team')}</div>
        <div className="team-text d-flex flex-column">
          <div>{t('about.team_text_1')}</div>
          <div>{t('about.team_text_2')}</div>
        </div>
      </div>
      {/* <div className="team-bottom d-flex">
        {team.map((member, index) => (
          <div className="team-card d-flex flex-column" key={index}>
            <img className="photo" src={member.img} alt="" />
            <div className="team-member d-flex flex-column">
              <div className="team-name">{member.name}</div>
              <div className="team-role gray">{member.role}</div>
            </div>
          </div>
        ))}
      </div> */}
    </section>
  )
}