export const VideoTypes = [
  'Рекламный',
  'Презентационный',
  'Анимационный',
  'Интервью',
  'Подкаст',
  'Обучающий',
  'Другой',
];

export const VideoTypesKk = [
  'Жарнамалы',
  'Презентациялық',
  'Анимациялық',
  'Интервью',
  'Подкаст',
  'Оқыту',
  'Басқа',
];

export const VideoDurationShort = [
  'До 30 секунд',
  '30 секунд - 1 минута',
  '1 минута - 5 минут',
  'Более 5 минут',
];

export const VideoDurationShortKk = [
  '30 секундқа дейін',
  '30 секунд - 1 минут',
  '1 минут - 5 минут',  
  '5 минуттан артық',
];

export const VideoDurationLong = [
  'До 1 часа',
  'Более 1 часа',
  'Ещё не решил/решила',
];

export const VideoDurationLongKk = [
  '1 сағаттан аз',
  '1 сағаттан астам',
  'Мен әлі шешкен жоқпын',
];

export const VideoMood = [
  'Юмористическое',
  'Серьёзное',
  'Эмоциональное',
  'Другое',
];

export const VideoMoodKk = [
  'Әзілқой',
  'Байыпты',
  'Эмоционалды',
  'Басқа',
];

export const VideoTone = ['Спокойный', 'Динамичный'];

export const VideoToneKk = ['Баяу', 'Динамикалық'];

export const VideoPlacement = ['Соц. сети', 'TV', 'LED', 'Другое'];

export const VideoPlacementKk = ['Әлеуметтік желі', 'TV', 'LED', 'Басқа'];

export const VideoAnimationNecessary = ['Да', 'Нет'];

export const VideoAnimationNecessaryKk = ['Иә', 'Жоқ'];

export const VideoAnimationType = ['2D', '3D', 'Другое'];

export const VideoAnimationTypeKk = ['2D', '3D', 'Басқа'];

export const VideoPeopleCount = ['1', '2', '3', '4+'];

export const VideoBudget = ['Ввести сумму', 'Обсуждается'];

export const VideoBudgetKk = ['Соманы енгізіңіз', 'Талқылануда'];
