import { isMobile } from "../..";
import one from '../../assets/images/about/1.png';
import two from '../../assets/images/about/2.png';
import three from '../../assets/images/about/3.png';
import Rock_1 from '../../assets/images/about/Rock_1.png';
import Rock_2 from '../../assets/images/about/Rock_2.png';
import Rock_3 from '../../assets/images/about/Rock_3.png';
import { useTranslation } from "react-i18next";

export default function Directions() {
  const { t } = useTranslation();
  const items = [
    {
      title: t('about.direction_title_1'),
      subtitle: t('about.direction_subtitle_1'),
      bg_left: one,
      bg_right: Rock_1,
    },
    {
      title: t('about.direction_title_2'),
      subtitle: t('about.direction_subtitle_2'),
      bg_left: two,
      bg_right: Rock_2,
    },
    {
      title: t('about.direction_title_3'),
      subtitle: t('about.direction_subtitle_3'),
      bg_left: three,
      bg_right: Rock_3,
    },
  ];

  return (
    <section className="directions">
      <div className="directions-card d-flex flex-column black-bg">
        <div className="directions-top d-flex flex-column">
          <div className="directions-title white">{t('about.directions')}</div>
          <div className="directions-text d-flex flex-column white-80">
            <div>{t('about.direction_text_1')}</div>
            <div>{t('about.direction_text_2')}</div>
          </div>
        </div>
        <div className={`directions-bottom d-flex ${isMobile && 'flex-column'}`}>
          {items.map((item, index) => (
            <div
              className="directions-item d-flex al-it-center"
              style={{ backgroundImage: `url(${item.bg_right})` }}
              key={index}
            >
              <img className="item-bg-left" src={item.bg_left} alt="" />
              <div className="item-text d-flex flex-column">
                <div className="item-title white">{item.title}</div>
                <div className="item-subtitle white-80">{item.subtitle}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}