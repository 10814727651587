import Freedom_Kids from '../../assets/images/portfolio/Freedom_Kids.png';
import Yenlik from '../../assets/images/portfolio/Yenlik.png';
import Freedom_Insurance from '../../assets/images/portfolio/Freedom_Insurance.png';
import Xiaomi from '../../assets/images/portfolio/Xiaomi.png';
import Samsung_Freestyle from '../../assets/images/portfolio/Samsung_Freestyle.png';
import Freedom_Finance_presentation from '../../assets/images/portfolio/Freedom_Finance_presentation.png';
import Freedom_x_Zhansaya_Abdumalik from '../../assets/images/portfolio/Freedom_Finance_x_Zhansaya_Abdumalik.png';
import The_Flow from '../../assets/images/portfolio/The_Flow.png';
import Freedom_Eco_System_2022 from '../../assets/images/portfolio/Freedom_Eco_System_2022.png';
import Freedom_Eco_System_2023 from '../../assets/images/portfolio/Freedom_Eco_System_2023.png';
import Kazakhstan_Chess_Federation from '../../assets/images/portfolio/Kazakhstan_Chess_Federation.png';
import Ol_Ne from '../../assets/images/portfolio/Ol_Ne.png';
import Garyshker from '../../assets/images/portfolio/Garyshker.png';
import Rams_Kazakhstan from '../../assets/images/portfolio/Rams_Kazakhstan.png';
import Six_Inch from '../../assets/images/portfolio/6inch.png';
import Forbes_x_ABR from '../../assets/images/portfolio/Forbes_x_ABR.png';
import Freedom_Talks from '../../assets/images/portfolio/Freedom_Talks.png';
// import Freedom_Broker from '../../assets/images/portfolio/Freedom_Broker.png';
// import Freedom_Lesson from '../../assets/images/portfolio/Freedom_Lesson.png';
// import Forbes_QnA from '../../assets/images/portfolio/Forbes_QnA.png';

export const tabs = [
  'all',
  'ad',
  'presentation',
  'animation',
  'interview',
  'podcast',
  'lesson',
  'other',
];

export const cards = [
  {
    type: 'ad',
    title: 'Freedom Eco System | 2023',
    client: 'Freedom Finance',
    duration: '01:40',
    image: Freedom_Eco_System_2023,
    url: '892219391',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'ad',
    title: 'Freedom Kids',
    client: 'Freedom Finance',
    duration: '00:30',
    image: Freedom_Kids,
    url: '841422831',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'other',
    title: 'Yenlik',
    client: 'Yenlik',
    duration: '02:52',
    image: Yenlik,
    url: '814225411',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'ad',
    title: 'Freedom Finance Insurance',
    client: 'Freedom Finance',
    duration: '00:36',
    image: Freedom_Insurance,
    url: '672738347',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'ad',
    title: 'Xiaomi',
    client: 'Xiaomi',
    duration: '00:44',
    image: Xiaomi,
    url: '701911246',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'ad',
    title: 'Samsung Freestyle',
    client: 'Samsung',
    duration: '01:14',
    image: Samsung_Freestyle,
    url: '701908586',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'presentation',
    title: 'Freedom Finance Broker',
    client: 'Freedom Finance',
    duration: '00:33',
    image: Freedom_Finance_presentation,
    url: '656941395',
    host: 'vimeo',
    vertical: false,
  },
  // {
  //   type: 'ad',
  //   title: 'Freedom Broker',
  //   client: 'Freedom Broker',
  //   duration: '00:30',
  //   image: Freedom_Broker,
  //   url: '866657822',
  //   host: 'vimeo',
  //   vertical: false,
  // },
  {
    type: 'ad',
    title: 'Freedom x Zhansaya Abdumalik',
    client: 'Freedom Finance',
    duration: '00:46',
    image: Freedom_x_Zhansaya_Abdumalik,
    url: '574793706',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'ad',
    title: 'Flow Education',
    client: 'Flow Education',
    duration: '01:00',
    image: The_Flow,
    url: '559860942',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'animation',
    title: 'Freedom Eco System | 2022',
    client: 'Freedom Finance',
    duration: '01:26',
    image: Freedom_Eco_System_2022,
    url: '814187352',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'animation',
    title: 'Kazakhstan Chess Federation',
    client: 'Kazakhstan Chess Federation',
    duration: '00:55',
    image: Kazakhstan_Chess_Federation,
    url: '866303494',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'animation',
    title: 'Ol ne?',
    client: 'Garyshker',
    duration: '5:54',
    image: Ol_Ne,
    url: 'watch?v=UR4DCzyOgkY&list=PLgMHpCBJlNZaxW6PcAv4aWRZ4hYQn-qAg',
    host: 'youtube',
    vertical: false,
  },
  {
    type: 'animation',
    title: 'Garyshker',
    client: 'Garyshker',
    duration: '00:46',
    image: Garyshker,
    url: '383578692',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'ad',
    title: 'Rams Kazakhstan',
    client: 'Rams Kazakhstan',
    duration: '00:34',
    image: Rams_Kazakhstan,
    url: '866292573',
    host: 'vimeo',
    vertical: false,
  },
  {
    type: 'ad',
    title: '6inch',
    client: '6inch',
    duration: '01:00',
    image: Six_Inch,
    url: '650230216',
    host: 'vimeo',
    vertical: false,
  },
  // {
  //   type: 'lesson',
  //   title: 'Freedom Broker',
  //   client: 'Freedom Broker',
  //   duration: '3:43',
  //   image: Freedom_Lesson,
  //   url: 'FYqYRHNablM',
  //   host: 'youtube',
  //   vertical: false,
  // },
  {
    type: 'interview',
    title: 'Forbes x ABR',
    client: 'Forbes',
    duration: '49:41',
    image: Forbes_x_ABR,
    url: 'GJB4ltyEJ7Q',
    host: 'youtube',
    vertical: false,
  },
  // {
  //   type: 'interview',
  //   title: 'Forbes QnA',
  //   client: 'Forbes',
  //   duration: '59:54',
  //   image: Forbes_QnA,
  //   url: 'clXFREKJXlw',
  //   host: 'youtube',
  //   vertical: false,
  // },
  {
    type: 'podcast',
    title: 'Freedom Talks',
    client: 'Freedom Holding',
    duration: '1:00:00',
    image: Freedom_Talks,
    url: '867149005',
    host: 'vimeo',
    vertical: true,
  },
];
