import { isMobile } from "../..";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
  const { t } = useTranslation();

  return (
    <section className={`about-us d-flex ${isMobile && 'flex-column'}`}>
      <div className="about-left d-flex flex-column">
        <div className='about-left-top d-flex flex-column'>
          <div className='about-title'>{t('about.main_title')}</div>
          <div className='about-left-text d-flex flex-column'>
            <div>{t('about.text_1')}</div>
            <div>{t('about.text_2')}</div>
          </div>
        </div>
        <div className='about-left-text d-flex flex-column'>
          <div>{t('about.text_3')}</div>
          <div>{t('about.text_4')}</div>
          <div>{t('about.text_5')}</div>
        </div>
      </div>
      <div className="about-right d-flex flex-column">
        <div className="about-right-top d-flex flex-column">
          <div className='about-right-title'>{t('about.principles')}</div>
          <div className='about-right-text d-flex flex-column'>
            <div className='about-right-text-item'>{t('about.principle_1')}</div>
            <div className='about-right-text-item'>{t('about.principle_2')}</div>
            <div className='about-right-text-item'>{t('about.principle_3')}</div>
            <div className='about-right-text-item'>{t('about.principle_4')}</div>
            <div className='about-right-text-item'>{t('about.principle_5')}</div>
          </div>
        </div>
        <div className="about-right-bottom d-flex flex-column">
          <div className='about-right-title'>{t('about.mission')}</div>
          <div className='about-right-text-item'>{t('about.mission_text')}</div>
        </div>
      </div>
    </section>
  )
}