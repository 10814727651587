import './styles.scss';
import freedom from '../../assets/images/main/freedom_logo.png'
import xiaomi from '../../assets/images/main/xiaomi_logo.png'
import beeline from '../../assets/images/main/beeline_logo.png'
import philip_morris from '../../assets/images/main/pmk_logo.png'
import lexus from '../../assets/images/main/lexus_logo.png'
import huawei from '../../assets/images/main/huawei_logo.png'
import forbes from '../../assets/images/main/forbes_logo.png'
import samsung from '../../assets/images/main/samsung_logo.png'
import toyota from '../../assets/images/main/toyota_logo.png'
import rams from '../../assets/images/main/rams_logo.png'
import { useTranslation } from 'react-i18next';

export default function Clients() {
  const clients = [
    freedom,
    xiaomi,
    beeline,
    philip_morris,
    lexus,
    huawei,
    forbes,
    samsung,
    toyota,
    rams,
  ];
  const { t } = useTranslation();

  return (
    <section className='main-clients d-flex flex-column'>
      <div className='section-title'>{t('main.our_clients')}</div>
      <div className='main-clients-list d-flex'>
        {clients.map((client, index) => (
          <img className='main-clients-item' key={index} src={client} alt="" />
        ))}
      </div>
    </section>
  )
}