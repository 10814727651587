import './styles.scss';
import Modal from '../modal';
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

export default function ApplicationForm() {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const onName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
  const onPhone = (event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.target.value);
  const { t } = useTranslation();

  const onSubmit = async () => {
    const formData = new FormData();
    
    formData.append('entry.1209307401', name);
    formData.append('entry.1127527797', phone);

    fetch(
      "https://docs.google.com/forms/d/e/1FAIpQLSebreeTFAk1Sa7rdJagLlFzwi-UxQuM7gFnceiX4LgILPN-yQ/formResponse",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: formData,
      }
    )
      .then(() => {
        setIsModalVisible(true);
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    if (name && phone) setIsValid(true);
    else setIsValid(false);
  }, [name, phone]);

  return (
    <section className='form-container'>
      <div className="form-card black-bg d-flex flex-column al-it-center">
        <div className='form-header d-flex flex-column al-it-center text-center'>
          <div className="section-title white">{t('main.application_title')}</div>
          <div className='white-80'>{t('main.application_subtitle')}</div>
        </div>
        <div className='form-main d-flex flex-column'>
          <div className='form-inputs d-flex flex-column'>
            <input className='white-80' type="text" placeholder={t('main.application_name')} onChange={onName} />
            <input className='white-80' type="text" placeholder={t('main.application_phone')} onChange={onPhone} />
          </div>
          <button
            className='form-submit-btn gradient white pointer'
            disabled={!isValid}
            onClick={onSubmit}
          >
            {t('main.send')}
          </button>
        </div>
        <div className='white-40 text-center'>{t('main.send_warning_1')} {t('main.send_warning_2')}</div>
      </div>
      <Modal visible={isModalVisible} hideModal={() => setIsModalVisible(false)} isMainForm={true} />
    </section>
  )
}