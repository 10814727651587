export const about = {
  main_title: 'Біз туралы',
  text_1: 'Біз – yume.pro, бейне өндіріс студиясы.',
  text_2: 'Күн сайын сіз көптеген бейнелер мен фото мазмұнға тап боласыз. Бұл мазмұнның жақсы бөлігін біз жасадық.',
  text_3: 'Біз мақсатты шығармашылық шабыттандырады және дұрыс команда кез келген сәтті өндірістің кілті деп санаймыз.',
  text_4: 'Біздің қызығушылықтарымыз жұмысымызға әсер етеді және керісінше.',
  text_5: 'Біз өз ісімізді жақсы көреміз, сондықтан оны жақсы жасаймыз.',
  principles: 'Принциптеріміз',
  principle_1: 'Клиентке бағдарлану',
  principle_2: 'Профессионализм',
  principle_3: 'Инновациялар',
  principle_4: 'Адалдық',
  principle_5: 'Командалық рух',
  mission: 'Миссия',
  mission_text: 'Тапсырыс беруші мен мердігер арасындағы ыңғайлы көпір болу, сапалы қызмет көрсету және бизнесінің өсуіне үлес қосу арқылы өз мақсаттарына жетуге көмектесу.',
  directions: 'Бағыттарымыз',
  direction_text_1: 'Біздің компания бастаған бірінші бағыт бейнеконтент өндіру болды.',
  direction_text_2: 'Бірақ уақыт өте келе біз өсіп, кеңейе бастадық.',
  direction_title_1: 'Продакшен',
  direction_title_2: 'Байланыс арналары',
  direction_title_3: 'Дизайн',
  direction_subtitle_1: 'Фото және бейне өндірісі, кез келген күрделіліктегі шығармашылық түсірілім',
  direction_subtitle_2: 'SMM, PR науқандары, ықпал етушілермен жұмыс',
  direction_subtitle_3: 'Айдентика, брендинг, презентациялар, арт-дирекшн, key visuals, графика',
  we_film: 'Біздің түсіретініміз',
  ad: 'Жарнамалық роликтер',
  presentation: 'Презентациялық роликтер',
  interview: 'Интервью',
  animation: 'Анимациялық',
  podcast: 'Подкасттар',
  lesson: 'Оқыту',
  ad_text: 'өнімді немесе қызметті визуализациялау және жылжыту үшін',
  presentation_text: 'бренд туралы хабардарлықты арттыру',
  interview_text: 'кейіпкерді аудитория алдында тереңірек дамыту үшін',
  animation_text: 'өнімді немесе қызметті визуализациялау және жылжыту үшін',
  podcast_text: 'өз брендіңізді заманауи форматта дамыту',
  lesson_text: 'компанияның қызметкерлері үшін немесе меншікті курстардың бөлігі ретінде',
  watch_projects: 'Жұмыстарды қарау',
  filming_process: 'Түсіру процессі',
  step_1: '1-қадам',
  step_2: '2-қадам',
  step_3: '3-қадам',
  step_4: '4-қадам',
  step_5: '5-қадам',
  step_6: '6-қадам',
  step_7: '7-қадам',
  step_1_text: 'Клиенттің ауырсынуын шешу қажеттілігі',
  step_2_text: 'Идеяны дамыту',
  step_3_text: 'Үйлестіру',
  step_4_text: 'Пре-продакшен',
  step_5_text: 'Түсірілім процессі',
  step_6_text: 'Пост-продакшен',
  step_7_text: 'Дайын жоба',
  team: 'Команда',
  team_text_1: 'Біз өз мамандарымыздың тобын әрбір жеке жобаның қажеттіліктеріне қарап бейімдейміз.',
  team_text_2: 'Біз жобаның қай кезеңінде екенімізді және келесі қадамды білгенді ұнатамыз. Біз сіздің де ұнататыныңызды білеміз.',
  birzhan: 'Біржан Шакарим',
  birzhan_role: 'Бас өндіруші',
  nikita: 'Никита Ким',
  nikita_role: 'Режиссёр және продюсер',
  clients_title: 'Біздің клиенттеріміз',
  clients_text_1: 'Біз ірі халықаралық холдингтермен немесе жергілікті стартаптармен жұмыс істесек те, ең ақылды шешімдер мен ең сәтті нәтижелерге ұмтыламыз.',
  clients_text_2: 'Соңғы 5 жылда біз бүкіл ТМД елдерінің компанияларымен 400-ден астам жобаны аяқтадық.',
}