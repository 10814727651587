import { nav } from "./locale_nav";
import { main } from "./locale_main";
import { footer } from "./locale_footer";
import { portfolio } from "./locale_portfolio";
import { about } from "./locale_about";
import { brief } from "./locale_brief";
import { modal } from "./locale_modal";

export const i18n_kk = {
  translation: {
    nav,
    main,
    footer,
    about,
    portfolio,
    brief,
    modal,
  },
};
