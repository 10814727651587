import { isMobile } from "../..";
import freedom from '../../assets/images/about/logo/freedom.png';
import xiaomi from '../../assets/images/about/logo/xiaomi.png';
import forbes from '../../assets/images/about/logo/forbes.png';
import samsung from '../../assets/images/about/logo/samsung.png';
import philip from '../../assets/images/about/logo/philip.png';
import lexus from '../../assets/images/about/logo/lexus.png';
import huawei from '../../assets/images/about/logo/huawei.png';
import toyota from '../../assets/images/about/logo/toyota.png';
import { useTranslation } from "react-i18next";

export default function AboutClients() {
  const { t } = useTranslation();
  const client_logos = [
    freedom,
    xiaomi,
    forbes,
    samsung,
    philip,
    lexus,
    huawei,
    toyota,
  ];
  return (
    <section className="about-clients">
      <div className={`about-clients-card d-flex al-it-center black-bg ${isMobile && 'flex-column'}`}>
        <div className="about-clients-left d-flex flex-column">
          <div className="about-title white">{t('about.clients_title')}</div>
          <div className="about-clients-text white-80">{t('about.clients_text_1')}</div>
          <div className="about-clients-text white-80">{t('about.clients_text_2')}</div>
        </div>
        <div className="about-clients-right d-flex">
          {client_logos.map((logo, index) => (
            <div className="about-clients-item d-flex" key={index}>
              <img src={logo} alt="" />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
