export const main = {
  slogan: 'Визуализируем масштабные идеи',
  showreel: 'Смотреть шоурил',
  project_count: 'реализованных проектов',
  life_time_years: '4 года',
  life_time: 'в сфере видео-производства',
  client_count: 'клиентов',
  watch_count: 'просмотров на роликах в соц. сетях',
  our_clients: 'Наши клиенты',
  application_title: 'Давайте творить вместе',
  application_subtitle: 'Оставьте заявку и получите бесплатную консультацию',
  application_name: 'Ваше имя',
  application_phone: 'Номер телефона',
  send: 'Отправить',
  send_warning_1: 'Нажимая на кнопку «Отправить»,',
  send_warning_2: 'вы даете согласие на обработку своих персональных данных',
}