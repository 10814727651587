import { isMobile } from "../..";
import Freedom_Kids from '../../assets/images/portfolio/Freedom_Kids.png';
import Freedom_Finance_presentation from '../../assets/images/portfolio/Freedom_Finance_presentation.png';
import Forbes_x_ABR from '../../assets/images/portfolio/Forbes_x_ABR.png';
import Garyshker from '../../assets/images/portfolio/Garyshker.png';
import Freedom_Talks from '../../assets/images/portfolio/Freedom_Talks.png';
import Freedom_Lesson from '../../assets/images/portfolio/Freedom_Lesson.png';
import arrow from '../../assets/icons/arrow_top_right_white.svg'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ToPortfolio() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const videos = [
    {
      title: t('about.ad'),
      subtitle: t('about.ad_text'),
      bg: Freedom_Kids,
      type: 'ad',
    },
    {
      title: t('about.presentation'),
      subtitle: t('about.presentation_text'),
      bg: Freedom_Finance_presentation,
      type: 'presentation',
    },
    {
      title: t('about.interview'),
      subtitle: t('about.interview_text'),
      bg: Forbes_x_ABR,
      type: 'interview',
    },
    {
      title: t('about.animation'),
      subtitle: t('about.animation_text'),
      bg: Garyshker,
      type: 'animation',
    },
    {
      title: t('about.podcast'),
      subtitle: t('about.podcast_text'),
      bg: Freedom_Talks,
      type: 'podcast',
    },
    {
      title: t('about.lesson'),
      subtitle: t('about.lesson_text'),
      bg: Freedom_Lesson,
      type: 'lesson',
    },
  ];
  
  const navigateToPortfolio = (type: string) => {
    navigate('/portfolio', {state: { type: type }});
  };

  return (
    <section className='videos d-flex flex-column'>
      <div className='about-title'>{t('about.we_film')}</div>
      <div className={`videos-list d-flex ${isMobile && 'flex-column'}`}>
        {videos.map((vid, index) => (
          <div
            className='video-bg pointer'
            style={{ backgroundImage: `url(${vid.bg})` }}
            key={index}
            onClick={() => navigateToPortfolio(vid.type)}
          >
            <div className='video-item d-flex flex-column justify-between'>
              <div className='video-item-text d-flex flex-column'>
                <div className='video-item-title white'>{vid.title}</div>
                <div className='video-item-subtitle white-80'>{vid.subtitle}</div>
              </div>
              <div className='to-portfolio d-flex al-it-center white'>
                {t('about.watch_projects')}
                <img src={arrow} alt="" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}