import i18n from "../../i18n";

export default function LangSwitch() {
  const isKK = i18n.language === "kk";

  const handleLangChange = (lang: "kk" | "ru") => i18n.changeLanguage(lang);

  return (
    <>
      <div className={`pointer ${isKK && "primary"}`} onClick={() => handleLangChange("kk")}>Қаз</div>
      <div className={`pointer ${!isKK && "primary"}`} onClick={() => handleLangChange("ru")}>Рус</div>
    </>
  );
}
