export const about = {
  main_title: 'О нас',
  text_1: 'Мы – yume.pro, студия видеопродакшена.',
  text_2: 'Каждый день вы сталкиваетесь с большим количеством видео и фото-контента. И значительную часть этого контента делали мы.',
  text_3: 'Мы считаем, что целенаправленное творчество вдохновляет и что правильная команда является ключом к любому успешному производству.',
  text_4: 'Наши интересы влияют на нашу работу и наоборот.',
  text_5: 'Мы любим то, что делаем, поэтому делаем это хорошо.',
  principles: 'Принципы',
  principle_1: 'Клиентоориентированность',
  principle_2: 'Профессионализм',
  principle_3: 'Инновации',
  principle_4: 'Честность',
  principle_5: 'Командный дух',
  mission: 'Миссия',
  mission_text: 'Быть комфортным мостом между клиентом и исполнителем, помочь достигнуть своих целей, предоставляя качественные услуги и способствуя росту их бизнеса.',
  directions: 'Наши направления',
  direction_text_1: 'Первым направлением, с которого начался путь нашей компании было производство видеоконтента.',
  direction_text_2: 'Но со временем мы стали расти и расширяться.',
  direction_title_1: 'Продакшен',
  direction_title_2: 'Каналы коммуникации',
  direction_title_3: 'Дизайн',
  direction_subtitle_1: 'Фото и видео продакшен, креативные съемки любой сложности',
  direction_subtitle_2: 'SMM, PR-кампании, работа с инфлюенсерами',
  direction_subtitle_3: 'Айдентика, брендинг, презентации, арт-дирекшн, key visuals, графика',
  we_film: 'Мы снимаем',
  ad: 'Рекламные ролики',
  presentation: 'Презентационные ролики',
  interview: 'Интервью',
  animation: 'Анимационные',
  podcast: 'Подкасты',
  lesson: 'Обучающие',
  ad_text: 'для визуализации и продвижения продукта или услуги',
  presentation_text: 'для повышения узнаваемости бренда',
  interview_text: 'для более глубокого раскрытия героя перед аудиторией',
  animation_text: 'для визуализации и продвижения продукта или услуги',
  podcast_text: 'для развития собственного бренда в современном формате',
  lesson_text: 'для сотрудников внутри компании, либо в рамках авторских курсов',
  watch_projects: 'Смотреть работы',
  filming_process: 'Съемочный процесс',
  step_1: 'Шаг 1',
  step_2: 'Шаг 2',
  step_3: 'Шаг 3',
  step_4: 'Шаг 4',
  step_5: 'Шаг 5',
  step_6: 'Шаг 6',
  step_7: 'Шаг 7',
  step_1_text: 'Потребность в решении боли клиента',
  step_2_text: 'Разработка идеи',
  step_3_text: 'Согласование',
  step_4_text: 'Пре-продакшен',
  step_5_text: 'Съемочный процесс',
  step_6_text: 'Пост-продакшен',
  step_7_text: 'Готовый проект',
  team: 'Команда',
  team_text_1: 'Мы адаптируем команду наших специалистов и наш подход к потребностям каждого отдельного проекта.',
  team_text_2: 'Нам нравится знать на каком этапе проекта мы находимся и каков следующий шаг. И мы знаем, что вам тоже.',
  birzhan: 'Биржан Шакарим',
  birzhan_role: 'Генеральный продюсер',
  nikita: 'Никита Ким',
  nikita_role: 'Режиссёр и продюсер',
  clients_title: 'Наши клиенты',
  clients_text_1: 'Независимо от того, работаем ли мы с крупными международными холдингами или с локальными стартапами, мы стремимся к самым разумным решениям и наиболее успешным результатам.',
  clients_text_2: 'За последние 5 лет мы завершили более 400 проектов с компаниями из стран всего СНГ.',
}