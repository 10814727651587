export const main = {
  slogan: 'Үлкен идеяларды бейнелейміз',
  showreel: 'Шоурил қарау',
  project_count: 'аяқталған жоба',
  life_time_years: '4 жыл',
  life_time: 'бейне өндірісі саласында',
  client_count: 'клиент',
  watch_count: 'астам көріністер',
  our_clients: 'Біздің клиенттер',
  application_title: 'Бірге өнер жасайық',
  application_subtitle: 'Өтінім қалдырып, тегін кеңес алыңыз',
  application_name: 'Атыңыз',
  application_phone: 'Телефон нөміріңіз',
  send: 'Жіберу',
  send_warning_1: '«Жіберу» түймесін басу арқылы',
  send_warning_2: 'сіз жеке деректеріңізді өңдеуге келісім бересіз',
}