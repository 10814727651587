import { isMobile } from "../..";
import { useTranslation } from "react-i18next";

export default function Process() {
  const { t } = useTranslation();
  const processes = [
    { step: t('about.step_1'), text: t('about.step_1_text') },
    { step: t('about.step_2'), text: t('about.step_2_text') },
    { step: t('about.step_3'), text: t('about.step_3_text') },
    { step: t('about.step_4'), text: t('about.step_4_text') },
    { step: t('about.step_5'), text: t('about.step_5_text') },
    { step: t('about.step_6'), text: t('about.step_6_text') },
    { step: t('about.step_7'), text: t('about.step_7_text') },
  ];

  return (
    <section className="process">
      <div className="process-card d-flex flex-column black-bg">
        <div className="about-title white">{t('about.filming_process')}</div>
        <div className={`process-list d-flex ${isMobile && 'flex-column'}`}>
          {processes.map((proc, index) => (
            <div className="process-item d-flex flex-column" key={index}>
              <div className="process-step">{proc.step}</div>
              <div className="process-text">{proc.text}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}