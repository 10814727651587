import Select from '../select';
import {
  VideoDurationLong,
  VideoDurationLongKk,
  VideoPeopleCount,
  VideoBudget,
  VideoBudgetKk,
} from '../brief_dict';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function InterviewForm({ videoInfo, videoInfoInBrief, showModal }: { videoInfo: any; videoInfoInBrief: boolean; showModal: () => void }) {
  const [isCleared, setIsCleared] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [videoDuration, setVideoDuration] = useState<string>('');
  const [videoPeopleCount, setVideoPeopleCount] = useState<string>('');
  const [videoShortDescription, setVideoShortDescription] = useState<string>('');
  const [videoExample, setVideoExample] = useState<string>('');
  const [videoBudgetString, setVideoBudgetString] = useState<string>('Ввести сумму');
  const [videoBudget, setVideoBudget] = useState<string>('Ввести сумму');
  const [name, setName] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const onVideoDuration = (type: string) => {setVideoDuration(type); handleClear(false)};
  const onVideoPeopleCount = (type: string) => {setVideoPeopleCount(type); handleClear(false)};
  const onSetVideoShortDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {setVideoShortDescription(event.target.value); handleClear(false)};
  const onSetVideoExample = (event: React.ChangeEvent<HTMLTextAreaElement>) => {setVideoExample(event.target.value); handleClear(false)};
  const onVideoBudgetString = (type: string) => {setVideoBudgetString(type); handleClear(false)};
  const onVideoBudget = (event: React.ChangeEvent<HTMLInputElement>) => {setVideoBudget(event.target.value); handleClear(false)};
  const onSetName = (event: React.ChangeEvent<HTMLInputElement>) => {setName(event.target.value); handleClear(false)};
  const onSetCompany = (event: React.ChangeEvent<HTMLInputElement>) => {setCompany(event.target.value); handleClear(false)};
  const onSetPhone = (event: React.ChangeEvent<HTMLInputElement>) => {setPhone(event.target.value); handleClear(false)};
  const onSetComment = (event: React.ChangeEvent<HTMLInputElement>) => {setComment(event.target.value); handleClear(false)};
  const { t } = useTranslation();
  
  const handleClear = (value: boolean) => {
    if (value) setIsCleared(true);
    else setIsCleared(false);
  };

  const onSubmit = async () => {
    const formData = new FormData();
    
    formData.append('entry.1664640248', videoDuration);
    formData.append('entry.370544860', videoPeopleCount);
    formData.append('entry.1504547217', videoShortDescription);
    formData.append('entry.1290749045', videoExample);
    formData.append('entry.1260082864', videoBudgetString);
    formData.append('entry.514279901', videoBudgetString === 'Ввести сумму' ? videoBudget : '');
    formData.append('entry.1321437277', name);
    formData.append('entry.623062854', company ? company : '');
    formData.append('entry.779312050', phone);
    formData.append('entry.525009341', comment ? comment : '');

    fetch(
      "https://docs.google.com/forms/d/e/1FAIpQLSdEHfIq9FEwua5ZHDUZ39pbkfVGg5JFfmNDw7jKB_iydjgf-w/formResponse",
      {
        method: "POST",
        mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: formData,
      }
    )
      .then(() => {
        showModal();
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    videoInfo(true);
    if (
      videoDuration?.length &&
      videoPeopleCount?.length &&
      videoShortDescription.length &&
      videoBudgetString?.length &&
      name &&
      phone
    ) {
      if (videoBudgetString === 'Ввести сумму') {
        if (videoBudget) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(true);
        setVideoBudget('');
      }
    } else {
      setIsValid(false);
    }
  }, [
    videoDuration,
    videoPeopleCount,
    videoShortDescription,
    videoExample,
    videoBudgetString,
    videoBudget,
    name,
    company,
    phone,
    comment,
  ]);

  useEffect(() => {
    if (videoInfoInBrief) {setIsCleared(true); setIsValid(false)};
  }, [videoInfoInBrief]);

  return (
    <>
      <div className='brief-item d-flex flex-column'>
        <div className='brief-title'>2. {t('brief.video_dur_title')}</div>
        <Select variants={VideoDurationLong} kkVariants={VideoDurationLongKk} setVariant={onVideoDuration} clearSelect={isCleared} />
      </div>
      <div className='brief-item d-flex flex-column'>
        <div className='brief-title'>3. {t('brief.video_people_count_title')}</div>
        <Select variants={VideoPeopleCount} setVariant={onVideoPeopleCount} clearSelect={isCleared} />
      </div>
      <div className='brief-item d-flex flex-column'>
        <div className='brief-title'>4. {t('brief.video_short_title')}</div>
        <textarea className='brief-textarea' placeholder={t('brief.video_short_description')} onChange={onSetVideoShortDescription} />
      </div>
      <div className='brief-item d-flex flex-column'>
        <div className='brief-title'>5. {t('brief.video_example_title')}</div>
        <textarea className='brief-textarea' placeholder={t('brief.video_example')} onChange={onSetVideoExample} />
      </div>
      <div className='brief-item d-flex flex-column'>
        <div className='brief-title'>6. {t('brief.video_budget_title')}</div>
        <Select variants={VideoBudget} kkVariants={VideoBudgetKk} setVariant={onVideoBudgetString} value={videoBudgetString} />
        {videoBudgetString === 'Ввести сумму' && (
          <input
            className="brief-input tenge"
            type="number"
            min="0"
            inputMode="numeric"
            pattern="[0-9]*"
            title="Non-negative integral number"
            placeholder={t('brief.video_budget_insert')}
            onChange={onVideoBudget}
          />
        )}
      </div>
      <div className='brief-item d-flex flex-column'>
        <div className='brief-title'>7. {t('brief.video_contacts_title')}</div>
        <input className="brief-input" type="text" placeholder={t('brief.name_input')} onChange={onSetName} />
        <input className="brief-input" type="text" placeholder={t('brief.company_input')} onChange={onSetCompany} />
        <input className="brief-input" type="tel" placeholder={t('brief.phone_input')} onChange={onSetPhone} />
        <input className="brief-input" type="text" placeholder={t('brief.comment_input')} onChange={onSetComment} />
      </div>
      <div className='brief-footer d-flex al-it-center'>
        <button className='brief-btn white gradient pointer' disabled={!isValid} onClick={onSubmit}>{t('main.send')}</button>
        <div className='brief-btn-right d-flex flex-column'>
          <span>{t('main.send_warning_1')}</span>
          <span>{t('main.send_warning_2')}</span>
        </div>
      </div>
    </>
  );
}
