import './styles.scss';
import { useTranslation } from 'react-i18next';

export default function Stats() {
  const { t } = useTranslation();
  const stats = [
    { title: '500+', text: t('main.project_count') },
    { title: t('main.life_time_years'), text: t('main.life_time') },
    { title: '40+', text: t('main.client_count') },
    { title: '6.000.000+', text: t('main.watch_count') },
  ];

  return (
    <section className='stats'>
      <div className="stats-list d-flex">
        {stats.map((stat, index) => (
          <div className="stats-item d-flex flex-column" key={index}>
            <div className="stats-title">{stat.title}</div>
            <div className="stats-text">{stat.text}</div>
          </div>
        ))}
      </div>
    </section>
  )
}