import "./styles.scss";
import { isMobile } from "../..";
import logo_white from "../../assets/images/logo_white.png";
import logo_black from "../../assets/images/logo_black.png";
import AsideMobile from "./aside_mobile";
import { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Nav() {
  const location = useLocation();
  const [isAsideVisible, setIsAsideVisible ] = useState<boolean>(false);
  const { t } = useTranslation();
  
  const isMain = () => {
    return location.pathname === '/' ? true : false;
  }

  useEffect(() => {
    if (isAsideVisible) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [isAsideVisible]);
  
  return (
    <header className={`nav d-flex al-it-center justify-between ${!isMain() && 'not-main'}`}>
      <Link to={'/'} className="nav-logo pointer">
        <img src={isMain() ? logo_white : logo_black} alt="" />
      </Link>
      {!isMobile ? (
        <div className="nav-right d-flex">
          <div className="nav-links d-flex al-it-center">
            <Link to={'/'} className={`nav-link ${isMain() && 'white'}`}>{t('nav.to_main')}</Link>
            <Link to={'/about'} className={`nav-link ${isMain() && 'white'}`}>{t('nav.to_about')}</Link>
            <Link to={'/portfolio'} className={`nav-link ${isMain() && 'white'}`}>{t('nav.to_portfolio')}</Link>
          </div>
          <Link to={'/brief'} className="nav-link white fill-brief-btn gradient">
            <span style={{ marginRight: 8 }}>✦</span> {t('nav.to_fill_brief')}
          </Link>
        </div>
      ) : (
        <div className="nav-right d-flex" onClick={() => setIsAsideVisible(true)}>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="icon">
              <path
                id="shape"
                d="M4 17H20M4 12H20M4 7H20"
                stroke={isMain() ? 'white' : '#0B1320'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </div>
      )}
      {isMobile && <AsideMobile showAside={isAsideVisible} hideAside={() => setIsAsideVisible(false)} />}
    </header>
  )
}