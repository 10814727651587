export const brief = {
  video_type_title: 'Выберите тип ролика',
  video_dur_title: 'Длительность ролика',
  video_mood_title: 'Настроение ролика',
  video_tone_title: 'Тон видео',
  video_placement_title: 'Каналы размещения',
  video_animation_title: 'Нужна ли анимация',
  video_animation_type_title: 'Тип анимации',
  video_people_count_title: 'Кол-во спикеров в кадре',
  video_other_title: 'Опишите, какой ролик вам нужен',
  video_short_title: 'Краткое описание проекта',
  video_example_title: 'Примеры похожих работ (необязательно)',
  video_other_description: 'Описание',
  video_short_description: 'Краткое описание',
  video_example: 'Пример',
  video_budget_title: 'Предполагаемый бюджет',
  video_budget_insert: 'Введите сумму',
  file_title: 'Прикрепите любой документ о проекте(необязательно)',
  file_btn: 'Загрузить файл',
  video_contacts_title: 'Контактные данные',
  name_input: 'Ваше имя',
  company_input: 'Название компании (необязательно)',
  phone_input: 'Номер телефона',
  comment_input: 'Комментарий',
}