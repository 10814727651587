import './styles.scss';
import Nav from '../header/nav';
import Select from './select';
import Footer from '../footer';
import { VideoTypes, VideoTypesKk } from './brief_dict';
import AdForm from './forms/ad_form';
import PresentationForm from './forms/presentation_form';
import AnimationForm from './forms/animation.form';
import InterviewForm from './forms/interview_form';
import PodcastForm from './forms/podcast_form';
import LessonForm from './forms/lesson_form';
import OtherForm from './forms/other_form';
import Modal from '../modal';
import { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

export default function BriefPage() {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [videoType, setVideoType] = useState<string>('');
  const [videoInfo, setVideoInfo] = useState<boolean>(false);
  const onVideoType = (type: any) => setVideoType(type);
  const onVideoInfo = (info: any) => setVideoInfo(info);
  const clearVideoInfo = () => setVideoInfo(false);
  const { t } = useTranslation();

  useEffect(() => {
    document.title = 'yume.pro | Заполнить бриф';
  }, []);

  useEffect(() => {
    clearVideoInfo();
  }, [videoType]);

  return (
    <>
      <Nav />
      <section className={`brief d-flex flex-column ${!videoType && 'not-selected'}`}>
        <div className='brief-item type d-flex flex-column'>
          <div className='brief-title'>1. {t('brief.video_type_title')}</div>
          <Select variants={VideoTypes} kkVariants={VideoTypesKk} setVariant={onVideoType} />
        </div>
        <div className={`brief-item-invisible d-flex flex-column ${videoType === VideoTypes[0] && 'visible'}`}>
          <AdForm videoInfo={onVideoInfo} videoInfoInBrief={videoInfo} showModal={() => setIsModalVisible(true)} />
        </div>
        <div className={`brief-item-invisible d-flex flex-column ${videoType === VideoTypes[1] && 'visible'}`}>
          <PresentationForm videoInfo={onVideoInfo} videoInfoInBrief={videoInfo} showModal={() => setIsModalVisible(true)} />
        </div>
        <div className={`brief-item-invisible d-flex flex-column ${videoType === VideoTypes[2] && 'visible'}`}>
          <AnimationForm videoInfo={onVideoInfo} videoInfoInBrief={videoInfo} showModal={() => setIsModalVisible(true)} />
        </div>
        <div className={`brief-item-invisible d-flex flex-column ${videoType === VideoTypes[3] && 'visible'}`}>
          <InterviewForm videoInfo={onVideoInfo} videoInfoInBrief={videoInfo} showModal={() => setIsModalVisible(true)} />
        </div>
        <div className={`brief-item-invisible d-flex flex-column ${videoType === VideoTypes[4] && 'visible'}`}>
          <PodcastForm videoInfo={onVideoInfo} videoInfoInBrief={videoInfo} showModal={() => setIsModalVisible(true)} />
        </div>
        <div className={`brief-item-invisible d-flex flex-column ${videoType === VideoTypes[5] && 'visible'}`}>
          <LessonForm videoInfo={onVideoInfo} videoInfoInBrief={videoInfo} showModal={() => setIsModalVisible(true)} />
        </div>
        <div className={`brief-item-invisible d-flex flex-column ${videoType === VideoTypes[6] && 'visible'}`}>
          <OtherForm videoInfo={onVideoInfo} videoInfoInBrief={videoInfo} showModal={() => setIsModalVisible(true)} />
        </div>
      </section>
      {videoType && <Footer />}
      <Modal visible={isModalVisible} hideModal={() => setIsModalVisible(false)} />
    </>
  )
}