import './styles.scss';
import { useState, useEffect } from 'react';
import i18n from "../../i18n";

export default function Select({ variants, kkVariants, setVariant, clearSelect, multiple, value }: { variants: any; kkVariants?: any; setVariant: any; clearSelect?: boolean; multiple?: boolean; value?: string }) {
  const [selected, setSelected] = useState<any>();
  const isKK = i18n.language === "kk";

  const onSelect = (variant: any) => {
    if (multiple) {
      if (selected?.includes(variant)) {
        setSelected(
          selected.filter((v: any) => {
            return v !== variant;
          })
        );
      } else {
        setSelected([...selected, variant]);
      }
    } else {
      setSelected(variant);
    }
  };

  const isSelected = (variant: any) => {
    if (multiple) {
      return selected?.includes(variant) ? true : false;
    } else {
      return variant === selected ? true : false;
    }
  };

  const getVariant = (index: number) => {
    if (isKK && kkVariants) return kkVariants[index];
    else return variants[index];
  };

  useEffect(() => {
    setVariant(selected);
  }, [selected]);

  useEffect(() => {
    if (value) onSelect(value);
    else if (clearSelect) setSelected([]);
  }, [value, clearSelect]);

  return (
    <div className='select-list d-flex'>
      {variants.map((variant: any, index: number) => (
        <div
          className={`select-item d-flex al-it-center pointer ${
            isSelected(variant) && 'selected white black-bg'
          }`}
          key={index}
          onClick={() => onSelect(variant)}
        >
          {!multiple && <span className='select-item-bullet black-bg' />}
          <span>{getVariant(index)}</span>
        </div>
      ))}
    </div>
  );
}
