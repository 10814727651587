import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import './index.scss';
import MainPage from "./components/main_page";
import AboutPage from "./components/about";
import PortfolioPage from "./components/portfolio";
import BriefPage from "./components/brief";
import reportWebVitals from './reportWebVitals';
import './i18n';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<MainPage />} />
      <Route path="about" element={<AboutPage />} />
      <Route path="portfolio" element={<PortfolioPage />} />
      <Route path="brief" element={<BriefPage />} />
    </>
  )
);

export const isMobile = window.innerWidth > 1024 ? false : true;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
