import './styles.scss';
import Header from '../header';
import Stats from '../stats';
import Portfolio from '../portfolio/';
import Clients from '../clients';
import ApplicationForm from '../application_form';
import Footer from '../footer';
import { useEffect } from 'react';

export default function MainPage() {

  useEffect(() => {
    document.title = 'yume.pro | Главная';
  }, []);

  return (
    <>
      <Header />
      <Stats />
      <Portfolio />
      <Clients />
      <ApplicationForm />
      <Footer />
    </>
  )
}