import LangSwitch from "../lang_switch";
import arrow_top_right_white from '../../assets/icons/arrow_top_right_white.svg'
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AsideMobile({ showAside, hideAside } : { showAside: boolean; hideAside: () => void }) {
  const { t } = useTranslation();
  const location = useLocation();
  
  const isMain = location.pathname === '/' ? true : false;
  const isAbout = location.pathname === '/about' ? true : false;
  const isPortfolio = location.pathname === '/portfolio' ? true : false;
  const isBrief = location.pathname === '/brief' ? true : false;

  return (
    <aside className={`aside d-flex flex-column justify-between black-bg ${showAside && 'visible'}`}>
      <div className="aside-header d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={hideAside}>
          <path d="M18 6L6 18M6.00001 6L18 18" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>
      <div className="nav-links d-flex flex-column">
        <Link to={'/'} className={`nav-link white ${isMain && 'primary'}`}>{t('nav.to_main')}</Link>
        <Link to={'/about'} className={`nav-link white ${isAbout && 'primary'}`}>{t('nav.to_about')}</Link>
        <Link to={'/portfolio'} className={`nav-link white ${isPortfolio && 'primary'}`}>{t('nav.to_portfolio')}</Link>
        <Link to={'/brief'} className={`nav-link white ${isBrief && 'primary'}`}>{t('nav.to_fill_brief')}</Link>
      </div>
      <div className="aside-lang d-flex white-80">
        <LangSwitch />
      </div>
      <div className="aside-footer d-flex justify-between al-it-center">
        <div className="aside-footer-left d-flex flex-column">
          <div className="aside-footer-contacts d-flex flex-column">
            <a className='white-80 underline' href='tel:+77020006369'>+7 702 000-63-69</a>
            <a className='white-80 underline' href='mailto:info@yume.pro'>info@yume.pro</a>
          </div>
          <a
            className='aside-footer-address d-flex flex-column underline'
            href='https://2gis.kz/almaty/geo/70000001041248171'
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className='white-80 underline'>{t('footer.address_line_1')}</div>
            <div className='white-80 underline'>{t('footer.address_line_2')}</div>
          </a>
        </div>
        <div className='aside-footer-right d-flex flex-column'>
          <a
            className='white-80 d-flex al-it-center underline'
            href='https://www.instagram.com/yume.pro/'
            target="_blank"
            rel="noopener noreferrer"
          >
            Instagram
            <img src={arrow_top_right_white} alt="" />
          </a>
          {/* <a
            className='white-80 d-flex al-it-center underline'
            href='https://www.instagram.com/yume.pro/'
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
            <img src={arrow_top_right_white} alt="" />
          </a> */}
          <a
            className='white-80 d-flex al-it-center underline'
            href='https://vimeo.com/yumepro/'
            target="_blank"
            rel="noopener noreferrer"
          >
            Vimeo
            <img src={arrow_top_right_white} alt="" />
          </a>
        </div>
      </div>
    </aside>
  )
}