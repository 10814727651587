import './styles.scss';
import close_icon from '../../assets/icons/close_icon.svg';
import instagram from '../../assets/icons/instagram_white.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function Modal({ visible, hideModal, isMainForm }: { visible: boolean; hideModal: () => void; isMainForm?: boolean }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onModalClose = () => {
    if (isMainForm) {
      hideModal();
      window.location.reload();
    } else {
      hideModal();
      navigate('/');
    }
  };

  useEffect(() => {
    if (visible) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [visible]);

  return (
    <div className={`modal-bg d-flex al-it-center justify-center ${visible && 'visible'}`}>
      <div className={`modal-body d-flex flex-column ${visible && 'visible'}`}>
        <div className="modal-header d-flex">
          <div className='modal-close d-flex al-it-center justify-center gray pointer' onClick={onModalClose}>
            <img src={close_icon} alt="" />
          </div>
        </div>
        <div className="modal-text d-flex flex-column">
          <div className='modal-title'>{isMainForm ? t('modal.main_form') : t('modal.brief_form')}</div>
          <div className='modal-subtitle'>{t('modal.subtitle')}</div>
        </div>
        <a
          className="modal-btn d-flex al-it-center justify-center gradient white"
          href='https://www.instagram.com/yume.pro/'
          target="_blank"
          rel="noopener noreferrer"
          onClick={onModalClose}
        >
          <img src={instagram} alt="" />
          <span>{t('modal.to_instagram')}</span>
        </a>
      </div>
    </div>
  )
}