import './styles.scss';
import { isMobile } from "../..";
import Nav from '../header/nav';
import EmbedOverlay from './embed_overlay';
import Footer from '../footer';
import play_icon from '../../assets/icons/play_icon.svg';
import arrow_right from '../../assets/icons/arrow_right.svg';
import { tabs, cards } from './portfolio_dict';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function PortfolioPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const fromAbout = location.state?.type;
  const isMain =  location.pathname === '/' ? true : false;
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [filteredCards, setFilteredCards] = useState<any>(cards);
  const [isEmbedVisible, setIsEmbedVisible] = useState<boolean>(false);
  const [cardClicked, setCardClicked] = useState<string[]>([]);
  const { t } = useTranslation();

  const changeTab = (tabType: string) => {
    if (tabType === 'all') {
      setSelectedTab(tabs[0]);
      setFilteredCards(cards);
    } else {
      setSelectedTab(tabType);
      setFilteredCards(cards.filter(card => {return card.type === tabType}));
    }
  };

  const onCardClick = (card: []) => {
    setIsEmbedVisible(true);
    setCardClicked(card);
  };

  const onEmbedClose = () => {
    setIsEmbedVisible(false);
    setTimeout(() => {
      setCardClicked([]);
    }, 500);
  };

  useEffect(() => {
    if (fromAbout) changeTab(fromAbout);
  }, [fromAbout]);

  useEffect(() => {
    if (isEmbedVisible) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [isEmbedVisible]);

  useEffect(() => {
    if (isMain) {
      if (isMobile) {
        setFilteredCards(cards.slice(0, 3));
      } else {
        setFilteredCards(cards.slice(0, 6));
      }
    };
  }, [isMain, isMobile]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = 'yume.pro | Портфолио';
  }, []);

  return (
    <>
      {!isMain && <Nav />}
      <section className='cards d-flex flex-column'>
        {isMain ? (
          <div className="section-title">{t('portfolio.main_title')}</div>
        ) : (
          <div className='cards-header d-flex flex-column'>
            <div className='section-title'>{t('portfolio.title')}</div>
            <div className='cards-tabs d-flex'>
              {tabs.map((tab, index) => (
                <div
                  className={`cards-tab-item pointer ${selectedTab === tab && 'active'}`}
                  key={index}
                  onClick={() => changeTab(tab)}
                >
                  {t(`portfolio.tabs.${tab}`)}
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="portfolio-wrapper d-flex flex-column al-it-center">
          <div className='cards-list d-flex'>
            {filteredCards.map((card: any, index: number) => (
              <div
                className='cards-item d-flex pointer'
                style={{backgroundImage: `url(${card.image})`}}
                key={index}
                onClick={() => onCardClick(card)}
              >
                <div className='overlay'></div>
                <div className='cards-item-text d-flex justify-between al-it-end'>
                  <div className='cards-item-text-left d-flex flex-column'>
                    <div className='main-text d-flex flex-column'>
                      <div className='section-title white'>{card.title}</div>
                      <div className='white-80'>{t(`portfolio.tabs.${card.type}`)}</div>
                    </div>
                    <div className='additional white-40'>{card.client} · {card.duration}</div>
                  </div>
                  <div className='play white'>
                    <img src={play_icon} alt="" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {isMain && (
            <button
              className='portfolio-watch-btn d-flex al-it-center white pointer'
              onClick={() => navigate('/portfolio')}
            >
              <span className='text'>{t('portfolio.watch')}</span>
              <span className='arrow'>
                <img src={arrow_right} alt="" />
              </span>
            </button>
          )}
        </div>
      </section>
      <EmbedOverlay isEmbed={isEmbedVisible} videoInfo={cardClicked} hideEmbed={onEmbedClose} />
      {!isMain && <Footer />}
    </>
  )
}