import './styles.scss';

export default function EmbedOverlay({ isEmbed, videoInfo, hideEmbed }: { isEmbed: boolean; videoInfo: any; hideEmbed:() => void }) {
  return (
    <div className={`embed-overlay ${isEmbed && "visible"}`} onClick={hideEmbed}>
      {videoInfo.host === 'vimeo' ? (
        <div className='vimeo-table'>
          <div className='vimeo-table-cell'>
            <div className={`vimeo-padding ${videoInfo.vertical && 'vertical'}`}>
              <iframe
                className="iframe vimeo"
                src={`https://player.vimeo.com/video/${videoInfo.url}`}
                allow="autoplay; fullscreen"
              ></iframe>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
        </div>
      ) : (
        <div className='youtube-display d-flex al-it-center justify-center'>
          <iframe
            className="iframe youtube"
            src={`https://www.youtube.com/embed/${videoInfo.url}`}
            title="YouTube video player"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; web-share"
          ></iframe>
        </div>
      )}
    </div>
  );
}
