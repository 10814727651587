import './styles.scss';
import { isMobile } from "../..";
import logo_full_black from '../../assets/images/logo_full_black.png'
import arrow_top_right from '../../assets/icons/arrow_top_right.svg'
import footer_instagram from '../../assets/icons/footer_instagram.svg'
import footer_vimeo from '../../assets/icons/footer_vimeo.svg'
import footer_telegram from '../../assets/icons/footer_telegram.svg'
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <section className='footer d-flex justify-between'>
      {!isMobile ? (
        <div className='footer-right d-flex'>
          <div className='footer-right-top d-flex'>
            <div className='footer-right-item d-flex flex-column'>
              <div className='footer-right-title'>{t('footer.address_title')}</div>
              <a
                className='footer-right-subtitle d-flex flex-column underline'
                href='https://2gis.kz/almaty/geo/70000001041248171'
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className='footer-right-text'>{t('footer.address_line_1')}</div>
                <div className='footer-right-text'>{t('footer.address_line_2')}</div>
              </a>
            </div>
            <div className='footer-right-item d-flex flex-column'>
              <div className='footer-right-title'>{t('footer.contacts_title')}</div>
              <div className='footer-right-subtitle d-flex flex-column'>
                <a className='footer-right-text underline' href='tel:+77020006369'>+7 702 000-63-69</a>
                <a className='footer-right-text underline' href='mailto:info@yume.pro'>info@yume.pro</a>
              </div>
            </div>
          </div>
          <div className='footer-right-item d-flex flex-column'>
            <div className='footer-right-title'>{t('footer.social_title')}</div>
            <div className='footer-right-subtitle media d-flex flex-column'>
              <a
                className='footer-right-text d-flex al-it-center underline'
                href='https://www.instagram.com/yume.pro/'
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
                <img src={arrow_top_right} alt="" />
              </a>
              {/* <div className='footer-right-text d-flex al-it-center underline'>
                Telegram
                <img src={arrow_top_right} alt="" />
              </div> */}
              <a
                className='footer-right-text d-flex al-it-center underline'
                href='https://vimeo.com/yumepro/'
                target="_blank"
                rel="noopener noreferrer"
              >
                Vimeo
                <img src={arrow_top_right} alt="" />
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className='footer-mobile d-flex flex-column'>
          <div className='contacts d-flex flex-column'>
            <a className='gray underline' href='tel:+77020006369'>+7 702 000-63-69</a>
            <a className='gray underline' href='mailto:info@yume.pro'>info@yume.pro</a>
            <a
              className='gray underline'
              href='https://2gis.kz/almaty/geo/70000001041248171'
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('footer.address_mobile')}
            </a>
          </div>
          <div className='logos d-flex'>
            <a
              href='https://www.instagram.com/yume.pro/'
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={footer_instagram} alt="" />
            </a>
            <a
              href='https://vimeo.com/yumepro/'
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={footer_vimeo} alt="" />
            </a>
            {/* <a
              href='https://www.instagram.com/yume.pro/'
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={footer_telegram} alt="" />
            </a> */}
          </div>
        </div>
      )}
      <div className='footer-left d-flex flex-column'>
        {!isMobile && (
          <div className='footer-left-logo'>
            <img src={logo_full_black} alt="" />
          </div>
        )}
        <div className={`footer-left-text d-flex al-it-center ${isMobile && 'gray'}`}>
          <span>© Yume Group 2023</span>
          {/* <span className='dot-4px'></span> */}
          {/* <span className='pointer underline'>{t('footer.polytics')}</span> */}
        </div>
      </div>
    </section>
  )
}