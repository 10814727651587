import './styles.scss';
import Nav from '../header/nav';
import AboutUs from './about_us';
import Directions from './directions';
import ToPortfolio from './to_portfolio';
import Process from './process';
import Team from './team';
import AboutClients from './about_clients';
import Footer from '../footer';

export default function AboutPage() {
  return (
    <>
      <Nav />
      <AboutUs />
      <Directions />
      <ToPortfolio />
      <Process />
      <Team />
      <AboutClients />
      <Footer />
    </>
  )
}