export const portfolio = {
  main_title: 'Соңғы жұмыстар',
  title: 'Портфолио',
  watch: 'Портфолионы қарау',
  tabs: {
    all: 'Барлық жобалар',
    ad: 'Жарнамалы',
    presentation: 'Презентациялық',
    animation: 'Анимациялық',
    interview: 'Интервью',
    podcast: 'Подкаст',
    lesson: 'Оқыту',
    other: 'Басқа',
  },
}