export const brief = {
  video_type_title: 'Ролик түрін таңдаңыз',
  video_dur_title: 'Роликтің ұзақтығы',
  video_mood_title: 'Роликтің көңіл-күйі',
  video_tone_title: 'Бейне үні',
  video_placement_title: 'Орналастыру арналары',
  video_animation_title: 'Анимация қажеттілігі',
  video_animation_type_title: 'Анимация түрі',
  video_people_count_title: 'Кадрдағы спикер саны',
  video_other_title: 'Қандай бейне қажет екенін сипаттаңыз',
  video_short_title: 'Жобаның қысқаша сипаттамасы',
  video_example_title: 'Ұқсас жұмыстардың мысалдары (міндетті емес)',
  video_other_description: 'Сипаттама',
  video_short_description: 'Қысқаша сипаттама',
  video_example: 'Мысал',
  video_budget_title: 'Болжамды бюджет',
  video_budget_insert: 'Соманы енгізіңіз',
  file_title: 'Жоба туралы кез-келген файлды тіркеңіз (міндетті емес)',
  file_btn: 'Файлды жүктеу',
  video_contacts_title: 'Байланыс ақпараты',
  name_input: 'Сіздің атыңыз',
  company_input: 'Компания атауы (міндетті емес)',
  phone_input: 'Телефон нөмірі',
  comment_input: 'Пікір',
}