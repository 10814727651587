import "./styles.scss";
import { isMobile } from "../..";
import Nav from "./nav";
import LangSwitch from "../lang_switch";
import EmbedOverlay from '../portfolio/embed_overlay';
import play_icon from '../../assets/icons/play_icon.svg';
import { useState, useEffect, useRef, RefObject } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Header() {
  const [isEmbedVisible, setIsEmbedVisible] = useState<boolean>(false);
  const [cardClicked, setCardClicked] = useState<{}>({});
  const showreel = { url: '871414103', host: 'vimeo' };
  const onShowreel = () => {
    setIsEmbedVisible(true);
    setCardClicked(showreel);
  };
  const onEmbedClose = () => {
    setIsEmbedVisible(false);
    setTimeout(() => {
      setCardClicked({});
    }, 500);
  };
  const { t } = useTranslation();
  
  const useOnScreen = (ref: RefObject<HTMLElement>) => {
    const observerRef = useRef<IntersectionObserver | null>(null);
    const [isOnScreen, setIsOnScreen] = useState(true);

    useEffect(() => {
      observerRef.current = new IntersectionObserver(([entry]) => {
        setIsOnScreen(entry.isIntersecting)
      });
    }, []);

    useEffect(() => {
      if (ref.current) {
        observerRef.current?.observe(ref.current);
        return () => {
          observerRef.current?.disconnect();
        };
      }

    }, [ref]);

    return isOnScreen;
  };
  const elementRef = useRef<HTMLDivElement>(null);
  const isOnScreen = useOnScreen(elementRef);

  useEffect(() => {
    if (isEmbedVisible) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'auto';
  }, [isEmbedVisible]);

  return (
    <section className={`top ${!isOnScreen && !isMobile && 'shrink'}`}>
      <div className="top-bg d-flex flex-column">
        <Nav />
        <div className={`header-center d-flex ${isMobile ? 'flex-column justify-center' : 'al-it-center'}`}>
          <div className="header-title white" ref={elementRef}>{t('main.slogan')}</div>
          {isMobile && (
            <Link to={'/brief'} className="nav-link white fill-brief-btn gradient">
              <span style={{ marginRight: 8 }}>✦</span> {t('nav.to_fill_brief')}
            </Link>
          )}
        </div>
        <div className="d-flex justify-between">
          <div className="header-bottom">
            <div
              className="left d-flex al-it-center pointer white"
              onClick={onShowreel}
            >
              <img src={play_icon} alt="" />
              <span>{t('main.showreel')}</span>
            </div>
          </div>
          {!isMobile && (
            <div className="header-bottom">
              <div className="right d-flex">
                <LangSwitch />
              </div>
            </div>
          )}
        </div>
      </div>
      <EmbedOverlay isEmbed={isEmbedVisible} videoInfo={cardClicked} hideEmbed={onEmbedClose} />
    </section>
  );
}
