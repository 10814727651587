export const portfolio = {
  main_title: 'Последние работы',
  title: 'Портфолио',
  watch: 'Смотреть портфолио',
  tabs: {
    all: 'Все',
    ad: 'Рекламные',
    presentation: 'Презентационные',
    animation: 'Анимационные',
    interview: 'Интервью',
    podcast: 'Подкасты',
    lesson: 'Обучающие',
    other: 'Другие',
  },
}